.house-and-land-scrollbar-v2 {
  scrollbar-gutter: stable;
}
.house-and-land-scrollbar-v2::-webkit-scrollbar {
  @apply w-[4px];
}
.house-and-land-scrollbar-v2::-webkit-scrollbar-thumb {
  @apply rounded-full bg-[#D0D0D0];
}
.h-unit-sidepanel-container.house-and-land-scrollbar-v2::-webkit-scrollbar-thumb {
  @apply h-36;
}
.house-and-land-scrollbar-v2::-webkit-scrollbar-track {
  @apply bg-[#DFDFDF];
}
.h-unit-sidepanel-container.custom-scrollbar::-webkit-scrollbar-thumb {
  @apply h-36;
}
