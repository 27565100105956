.no-scrollbar::-webkit-scrollbar {
  @apply hidden;
}

.no-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.custom-scrollbar {
  scrollbar-gutter: stable;
}

.custom-scrollbar::-webkit-scrollbar {
  @apply w-[3px];
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  @apply rounded-full bg-[#BBBBBB];
}

.custom-scrollbar::-webkit-scrollbar-track {
  @apply bg-transparent;
}

.h-unit-sidepanel-container.custom-scrollbar::-webkit-scrollbar-thumb {
  @apply h-36;
}

.image-blur {
  @apply scale-[4] blur-[50px];
}

.counter-list {
  list-style: none;
  counter-reset: item 0;
}
.counter-list .counter-list-item {
  counter-increment: item;
}
.counter-list .counter-list-item::before {
  content: counter(item) '. ';
}

.container-image-blur {
  @apply bg-cover bg-center bg-no-repeat blur-[4px];
}

.custom-secondary-scrollbar {
  scrollbar-gutter: stable;
}
.custom-secondary-scrollbar::-webkit-scrollbar {
  @apply w-1.5;
}
.custom-secondary-scrollbar::-webkit-scrollbar-thumb {
  @apply rounded-full bg-white/[.70];
}
.custom-secondary-scrollbar::-webkit-scrollbar-track {
  @apply rounded-full bg-white/[.35];
}
