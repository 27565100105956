.background-cover {
  @apply h-screen overflow-x-auto bg-cover bg-center object-cover;
}

.background-cover-no-scroll {
  @apply h-screen w-full overflow-hidden bg-cover bg-center object-cover;
}

.background-contain {
  @apply h-screen overflow-x-auto bg-contain bg-center bg-no-repeat;
}

.shimmer-container {
  @apply isolate space-y-5 overflow-hidden rounded-2xl bg-white/5 p-4 shadow-xl shadow-black/5 before:absolute before:inset-0 before:-translate-x-full before:animate-[shimmer_2s_infinite] before:bg-gradient-to-r before:from-transparent before:via-gray-50/40 before:via-20% before:to-transparent before:to-50%;
}
